html {
  font-family: poppins, sans-serif;
  font-size: 1.25rem;
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-img__center {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.slider {
  position: relative;
  display: block;
  width: 100%;
}

.slider * {
  outline: none;
  min-height: 1px;
  min-width: 1px;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: -webkit-grab;
  cursor: grab;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  display: -ms-flexbox;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
}

.slick-slide > div,
.slick-slide > div > .slide-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  vertical-align: top;
}

.slick-slide .slide-content {
  position: relative;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging a, .slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  position: relative;
  z-index: 20;
  list-style: none;
  padding-top: 0.75rem;
  padding-left: 0;
  text-align: center;
  line-height: 0;
}

@media (max-width: 768px) {
  .slick-dots {
    top: -2rem;
  }
}

.slick-dots > li {
  display: inline-block;
  vertical-align: top;
  padding: 0.15rem;
}

.slick-dots > li + li {
  margin-left: 0.25rem;
}

.slick-dots > li > button {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 50%;
  overflow: hidden;
  color: transparent;
  cursor: pointer;
}

.slick-dots > li.slick-active > button {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
}

.redactor-content h2 {
  font-weight: 600;
  font-size: 2.5rem;
}

.redactor-content h3 {
  font-weight: 600;
  font-size: 1.5rem;
  padding-top: 12.5%;
}

.redactor-content p {
  font-weight: 400;
  font-size: 1.5rem;
  padding-top: 4.166666666666666%;
}

.button-wrapper {
  display: inline-block;
}

.button-big {
  display: grid;
  position: relative;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.button-big .button-text {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  align-self: center;
  font-weight: 600;
  padding-right: 0.5rem;
}

.button-big i {
  grid-column: span 1 / span 1;
  grid-column-start: 2;
  align-self: center;
}

.webcam-button {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
  border-radius: 9999px;
  cursor: pointer;
  height: 6rem;
  position: relative;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(6px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-color: #32353B;
  --tw-ring-offset-width: 5px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 52, 68, var(--tw-ring-opacity));
  width: 6rem;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.webcam-button .button-icon {
  font-size: 1.875rem;
  line-height: 2.25rem;
  position: absolute;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.tab {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  min-height: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  width: 0px;
  z-index: -1;
  align-self: start;
}

.tab.tab-active {
  overflow: visible;
  position: static;
  visibility: visible;
  width: auto;
}

.image-container {
  height: 100vh;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: -1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  overflow-x: hidden;
  overflow-y: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;
}

.overlay.overlay-show {
  overflow-y: scroll;
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}

.overlay .overlay-inner {
  height: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.overlay.overlay-info {
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
}

.overlay.overlay-info.overlay-show {
  z-index: 9;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}

.overlay.overlay-info .overlay-close {
  display: block;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  margin-right: 2.5rem;
  text-align: right;
}

.overlay.overlay-info .overlay-close button {
  display: grid;
  margin-left: auto;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay.overlay-info .overlay-close button .overlay-btn-close-text {
  align-self: center;
  font-weight: 600;
  padding-right: 2.5rem;
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.overlay.overlay-info .overlay-close button i {
  font-size: 3rem;
  line-height: 1;
  grid-column: span 1 / span 1;
  grid-column-start: 2;
}

.overlay.overlay-info > .overlay-inner {
  overflow: auto;
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.info-overlay-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 8.333333333333332%;
}

.info-overlay-cell {
  grid-column: span 18 / span 18;
  grid-column-start: 4;
}

.info-overlay-cell .redactor-content * {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.overlay-open-button {
  cursor: pointer;
  font-size: 2.25rem;
  line-height: 2.5rem;
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(221, 52, 68, var(--tw-text-opacity));
}

.intro-text-container {
  cursor: pointer;
  display: grid;
  position: absolute;
  width: 62%;
  left: 50%;
  bottom: 30%;
  right: auto;
  transform: translate(-50%, 50%);
}

.intro-text-container i {
  font-size: 5rem;
  --tw-text-opacity: 1;
  color: rgba(221, 52, 68, var(--tw-text-opacity));
  align-self: flex-end;
  grid-column: span 1 / span 1;
  grid-column-start: 2;
}

.intro-text-container::after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
  display: block;
  width: 100%;
  grid-column: span 2 / span 2;
  grid-column-start: 1;
  margin-top: 2.5rem;
  height: 6px;
}

.intro-start-text {
  font-weight: 600;
  font-size: 5rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.video-aspect {
  position: relative;
  width: 100%;
  height: 70vh;
}

.video-container {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 13vh;
  transform: scale(1, 1) !important;
}

.canvas-image-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  margin-top: 13vh;
}

.webcam-button-container, .webcam-next-step-container {
  --tw-bg-opacity: 1;
  background-color: rgba(50, 53, 59, var(--tw-bg-opacity));
  position: relative;
  bottom: 0px;
}

.webcam-button-container,
.webcam-next-step-container {
  height: 30vh;
}

.taken-photo {
  display: none;
}

.webcam-button-container-wrapper {
  margin: auto auto;
}

.head-position-container {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  transform: scale(0.5);
}

.head-position-image {
  padding-top: 125%;
}

.info-center {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.image-slider-parent {
  --tw-bg-opacity: 1;
  background-color: rgba(50, 53, 59, var(--tw-bg-opacity));
  height: 100vh;
}

.dest-image-big {
  padding-top: 65vh;
}

.slider-button-container {
  height: 100%;
  margin-top: 2.5rem;
}

.slider-button-container .button-big {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.dest-images-nav {
  padding-top: 18vh;
}

.overlay.overlay-loading {
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s, z-index 0s linear 0.2s;
  top: auto;
}

.overlay.overlay-loading.overlay-show {
  z-index: 9999;
  background-color: transparent;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s, z-index 0s linear 0s;
}

.overlay.overlay-loading > .overlay-inner {
  overflow: auto;
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgba(50, 53, 59, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.overlay.overlay-loading .loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.overlay.overlay-loading .loading-container img {
  height: 500px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.send-image-outer-container {
  padding: 8.333333333333332%;
  padding-bottom: 16.666666666666664%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(50, 53, 59, var(--tw-bg-opacity));
}

.send-image-inner-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  height: 100%;
  position: relative;
  width: 100%;
}

.send-image-content-container {
  padding: 8.333333333333332%;
}

.email-input {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
  display: block;
  height: 4rem;
  font-size: 1.25rem;
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
  padding-left: 0.75rem;
  width: 100%;
}

.invalid {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 52, 68, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.language-switcher {
  font-weight: 600;
  font-size: 2.5rem;
  position: absolute;
  right: 2.5rem;
  bottom: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.language-switcher .current-language {
  --tw-text-opacity: 1;
  color: rgba(221, 52, 68, var(--tw-text-opacity));
}

.language-switcher li {
  display: inline-block;
}

.language-switcher li + li {
  padding-left: 0.75rem;
}